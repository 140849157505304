import React from "react"
import { X } from "lucide-react"

export const SelectedCollaborator = ({ selected, setSelected, selectedTeams, unselectTeams }) => {

  const removeCollabTag = (id) => {
    setSelected(selected.filter(tag => tag.id !== id))
  }

  return (
    <div className="mt-8">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <label className="text-sm font-medium text-gray-700">Collaborateurs ({selected.length}) :</label>
          <button className="p-1 rounded-full hover:bg-gray-100" onClick={(e) => {
            e.preventDefault()
            setSelected([])
          }}>
            <X className="w-4 h-4 text-gray-500" />
          </button>
        </div>
        <div className="flex flex-wrap gap-2 max-h-36 overflow-y-auto">
          {selected.map(tag => (
            <span
              key={tag.id}
              className="inline-flex items-center px-3 py-1 rounded-md bg-gray-100 text-sm"
            >
              {tag.firstname} {tag.lastname}
              <button
                onClick={() => removeCollabTag(tag.id)}
                className="ml-2 hover:bg-gray-200 rounded-full p-0.5"
              >
                <X className="w-3 h-3 text-gray-500" />
              </button>
            </span>
          ))}
        </div>
      </div>
    </div >
  )
}