import React from 'react';
import ModalCustom from '@components/UI/ModalCustom';
import { useTranslation } from 'react-i18next';

const AlertModal = ({ onConfirm, onCancel, duration }) => {
  const { t } = useTranslation();
  return (
    <ModalCustom
      isOpen={true}
      title={t('warning')}
      onModalClose={onCancel}
      onCancel={onCancel}
      onConfirm={onConfirm}
      className='w-16 h-16 flex flex-col justify-center items-center'
      customClass='shadow-modal h-auto'
      children={
        <div className='text-center whitespace-pre-line'>
          <p className='my-10'>
          {t('notEnoughLeaveN-1')}<br/>
          {t('leaveTaken', { count: duration})}
          </p>
        </div>
      }
    ></ModalCustom>
  );
};

export default AlertModal;
