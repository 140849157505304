import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Checkbox,
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Popover
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { useDebounce } from '@uidotdev/usehooks';
import Spinner from '@components/UI/Spinner';

export const SelectRequestCollab = ({
    data,
    setSearch,
    search,
    setPage,
    maxPage,
    page,
    selected,
    setSelected,
    setSelectedTeams,
    setAllSelected,
    isLoading,
    setIsLoading,
    searchTeams
}) => {
    const [expanded, setExpanded] = useState(['3']);
    const [searchLocal, setSearchLocal] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
    const inputRef = useRef(null);
    
    const totalPages = Math.ceil(data.length / itemsPerPage);
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    
    const paginatedData = data.slice(0, endIndex);

    const debouncedSearch = useDebounce(searchLocal, 600);

    useEffect(() => {
        setSearch(searchLocal);
    }, [debouncedSearch]);

    useEffect(() => {
        const fullySelectedTeams = data.reduce((acc, team) => {
            const allUsersSelected = team.users?.every(user =>
                selected.some(selectedUser => selectedUser.id === user.id)
            );
            if (allUsersSelected) {
                acc.push(team);
            }
            return acc;
        }, []);
        setSelectedTeams(fullySelectedTeams);
        setAllSelected(allSelected);
    }, [selected, data]);

    const handleMenuScrollToBottom = async () => {
        if (!isLoading && currentPage < totalPages) {
            setIsLoading(true);
            try {
                setCurrentPage(prev => prev + 1);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleToggleExpand = (deptId) => {
        setExpanded(prev =>
            prev.includes(deptId)
                ? prev.filter(id => id !== deptId)
                : [...prev, deptId]
        );
        inputRef.current?.focus();
    };

    const handleToggleUser = (user) => {
        setSelected(prev =>
            prev.some(selectedUser => selectedUser.id === user.id)
                ? prev.filter(selectedUser => selectedUser.id !== user.id)
                : [...prev, user]
        );
        inputRef.current?.focus();
    };

    const getUniqueUsersCount = (data) => {
        if (!searchTeams) return data.length;
        return new Set(data.flatMap(team => team.users?.map(user => user.id))).size;
    };

    const handleSelectAll = () => {
        const allUsers = [
            ...new Map(data.flatMap(team => team.users || []).map(user => [user.id, user])).values()
        ];
        searchTeams ? setSelected(prev => prev.length === allUsers.length ? [] : allUsers) : setSelected(prev => prev.length === data.length ? [] : data.map((user) => user));
        inputRef.current?.focus();
    };

    const allSelected = search.length === 0 ? getUniqueUsersCount(data) <= selected.length : '';

    const handleOpen = (event) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
            inputRef.current?.focus();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClickTeams = (team, e) => {
        e.stopPropagation();
        const listUsers = team.users || [];
        if (e.target.checked) {
            setSelected(prev => {
                const newUsers = listUsers.filter(user =>
                    !prev.some(selectedUser => selectedUser.id === user.id)
                );
                return [...prev, ...newUsers];
            });
            setSelectedTeams(prev => [...prev, team]);
        } else {
            setSelected(prev => prev.filter(user => !listUsers.some(teamUser => teamUser.id === user.id)));
            setSelectedTeams(prev => prev.filter(t => t.id !== team.id));
        }
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (open) {
            inputRef.current?.focus();
        }
    }, [open]);

    return (
        <Box sx={{ width: '100%', marginBottom: '14px' }}>
            <Box sx={{ position: 'relative', width: '100%', height: '48px' }}>
                <label className='text-sm font-medium text-gray-700'>Collaborateur*</label>
                <Box
                    onClick={handleOpen}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        height: '48px',
                    }}
                >
                    <input
                        ref={inputRef}
                        type="text"
                        value={searchLocal}
                        placeholder='Collaborateurs'
                        onChange={(e) => setSearchLocal(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                        className='text-sm'
                        style={{
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            width: '100%',
                            color: '#333',
                            cursor: 'pointer',
                        }}
                    />
                    <div className="flex items-center justify-center min-w-8 min-h-12 px-2 py-1 text-white bg-[#e20917] rounded-full text-sm mr-4">
                        {selected.length}
                    </div>
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        className={`ml-auto h-4 w-4 transition-transform duration-300 mr-4 ${open ? '-rotate-180' : ''}`}
                    />
                </Box>
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                sx={{ marginTop: '10px', width: '100%' }}
                onClick={handlePopoverClick}
            >
                <Paper
                    sx={{
                        width: '520px',
                        maxHeight: '600px',
                        overflow: 'auto'
                    }}
                    onScroll={(event) => {
                        const target = event.target;
                        const { scrollTop, clientHeight, scrollHeight } = target;
                        
                        if (scrollHeight - scrollTop <= clientHeight + 50 && !isLoading) {
                            handleMenuScrollToBottom();
                        }
                    }}
                >
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleSelectAll} className='mt-1'>
                            <Checkbox
                                edge="start"
                                checked={allSelected}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#e20917',
                                    },
                                    '&.MuiCheckbox-indeterminate': {
                                        color: "#e20917",
                                    },
                                }}
                            />
                            <ListItemText primary="Sélectionner tous les collaborateurs" />
                        </ListItemButton>
                    </ListItem>
                    <hr className='mx-4 border-t-2' />
                    {searchTeams ? (
                        <List sx={{ pt: 0 }}>
                            {paginatedData.map((team) => (
                                <Box key={team.id}>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => handleToggleExpand(team.id)}>
                                            <Checkbox
                                                edge="start"
                                                checked={team.users?.every(user =>
                                                    selected.some(selectedUser => selectedUser.id === user.id)
                                                )}
                                                indeterminate={
                                                    team.users?.some(user =>
                                                        selected.some(selectedUser => selectedUser.id === user.id)
                                                    ) &&
                                                    !team.users?.every(user =>
                                                        selected.some(selectedUser => selectedUser.id === user.id)
                                                    )
                                                }
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#e20917',
                                                    },
                                                    '&.MuiCheckbox-indeterminate': {
                                                        color: "#e20917",
                                                    },
                                                }}
                                                onClick={(e) => handleClickTeams(team, e)}
                                            />
                                            <ListItemText
                                                primary={<div style={{ fontWeight: 'bold' }}>{`${team.label} (${team.users?.length})`}</div>}
                                            />
                                            {expanded.includes(team.id) ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </ListItem>

                                    <Collapse in={expanded.includes(team.id)} timeout="auto">
                                        <List component="div" disablePadding>
                                            {team.users?.map((user) => (
                                                <ListItem key={user.id} disablePadding sx={{ pl: 4 }}>
                                                    <ListItemButton onClick={() => handleToggleUser(user)}>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={selected.some(selectedUser => selectedUser.id === user.id)}
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: '#e20917',
                                                                },
                                                                '&.MuiCheckbox-indeterminate': {
                                                                    color: "#e20917",
                                                                },
                                                            }}
                                                        />
                                                        <ListItemText primary={user.lastname + ' ' + user.firstname} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </Box>
                            ))}
                        </List>
                    ) : (
                        <List component="div" disablePadding>
                            {paginatedData.map((user) => (
                                <ListItem key={user.id} disablePadding >
                                    <ListItemButton onClick={() => handleToggleUser(user)}>
                                        <Checkbox
                                            edge="start"
                                            checked={selected.some(selectedUser => selectedUser.id === user.id)}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#e20917',
                                                },
                                                '&.MuiCheckbox-indeterminate': {
                                                    color: "#e20917",
                                                },
                                            }}
                                        />
                                        <ListItemText primary={user.lastname + ' ' + user.firstname} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                    {isLoading && (
                        <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spinner height={40} width={40} />
                        </ListItem>
                    )}
                </Paper>
            </Popover>
        </Box>
    );
};

export default SelectRequestCollab;